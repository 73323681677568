import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

import Disclaimer from '../components/Disclaimer/Disclaimer';
import Footer from '../blog/components/Footer';
import LinkWrapper from '../components/LinkWrapper/LinkWrapper';
import SiteNav from '../blog/components/Header/SiteNav';
import Wrapper from '../blog/components/Wrapper/Wrapper';
import { PostFullContent } from '../blog/components/PostContent';

import MetaData from '../containers/MetaData';

import { ROUTES } from '../routes';

import IndexLayout from '../layouts';
import { about } from '../website-config';
import { NoImage } from '../styles/templates/post.styles';
import { PostFull, PostFullHeader, PostFullTitle } from '../styles/templates/post.styles';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';

const About = () => {
  const photo = useStaticQuery(graphql`
    query {
      david: file(relativePath: { eq: "assets/david-nowak-hello.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 800, quality: 65) 
        }
      }
    }
  `);

  return (
    <IndexLayout>
      <MetaData location={about} robots="index, follow" />
      <Wrapper>
        <SiteHeader css={outer}>
          <div css={inner}>
            <SiteNav />
          </div>
        </SiteHeader>
        <SiteMain useTemplate useOuter>
          <PostFull className="post page" css={NoImage}>
            <PostFullHeader>
              <PostFullTitle>About David Nowak</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <p style={{ marginBottom: '0' }}>Hello there!</p>
                <div style={{ width: '800px', margin: '0 auto' }}>
                  <GatsbyImage image={photo.david.childImageSharp.gatsbyImageData} alt="David Nowak smiling" />
                </div>
                <p style={{ marginTop: '40px' }}>
                  I’m super happy and excited that you're visiting my site, and reading this right now!! I'm
                  so happy that I want to share a bunch of random (and maybe crazy) facts about myself. Think
                  of it as my cool introduction! This will also give you a little better idea of who I am as a
                  person and maybe you’ll connect with me on some of these?
                </p>
                <p>Soo let’s start! Here are some random highlights (so far) in my life:</p>
                <ul>
                  <li>
                    Moved half-way across the world (literally) for a relationship (...it didn’t work out)
                  </li>
                  <li>Quit a job to go traveling (...totally worth it!)</li>
                  <li>
                    Quit another job to start a business & also to pursue a relationship (...neither worked
                    out)
                  </li>
                  <li>Have climbed as high as 4800m</li>
                  <li>And destroyed my knees in the process (...oops)</li>
                  <li>Bought my dream motocross bike in my late teens</li>
                  <li>Taught myself how to program/code/develop (...what a nerd)</li>
                  <li>
                    Then successfully switched careers into tech just before turning 31 (...no, I'm a badass!)
                  </li>
                  <li>Failed at 5 businesses so far (...but still believe I’ll succeed)</li>
                  <li>Have lived in 3 countries, and have spent a longer time in 5 others</li>
                  <li>Have visited 18 countries and counting</li>
                  <li>
                    Still haven’t figured out where I want to live/settle in the world (...please help me)
                  </li>
                </ul>
                <p>
                  Now that you know a little bit about me, I’d also love to hear something about you! Send me
                  a message and say hello. Tell me what brought you here, what you like about the site or
                  whatever else you want (hello [at] davidpnowak.com).
                </p>
                <p>Okay, so continuing on… you might be wondering why I created this site?</p>
                <p>Well it was namely for 5 reasons:</p>
                <ol>
                  <li>
                    <b>Programming practice:</b> I wanted to update and re-design my website using the
                    JAMstack method due to some of its additional benefits and also to get more comfortable
                    using GraphQL.
                  </li>
                  <li>
                    <b>Professional presence:</b> I wanted my new site to look more professional, to have an
                    overall purpose, and still be able to showcase my programming portfolio projects.
                  </li>
                  <li>
                    <b>Blogging:</b> I wanted to be able to create content on my own website (blog posts,
                    articles, ...etc) and not have to pay a single cent for hosting. That is one of the
                    reasons why I chose to go with the JAMstack route, as it is a completely free setup!
                  </li>
                  <li>
                    <b>Inspiration:</b> By sharing the knowledge and experiences I’ve gained in switching
                    careers into programming, I hope to help others also make the switch into tech. Or at
                    least give you some insights, knowledge, motivation and inspiration to improve your own
                    life.
                  </li>
                  <li>
                    <b>Financial freedom:</b> By additionally documenting my journey towards financial freedom
                    in the areas of investing, programming and entrepreneurial side projects, I want this site
                    to grow into a valuable (and hopefully inspirational) resource. One that has a lot of
                    useful content for others to read and follow along.
                  </li>
                </ol>
                <p>
                  I want to highlight and expand upon the last point in a little bit more detail. As it is one
                  of my goals to achieve financial freedom, retire early and then be able to work on passion
                  projects. This would be the ultimate dream to not have to worry about money again, nor have
                  to follow strict budgets. However, when I ran the numbers to see how long it would take me
                  to reach this goal... I quickly realized something.
                </p>
                <p>
                  If I kept working for someone else at a job, had no other sources of income, and tried to
                  achieve this goal...
                </p>
                <p>It would NOT be possible!!</p>
                <p>
                  When I found this out... it sucked in multiple ways. But not only that, it also stung and
                  hurt!
                </p>
                <p>
                  I realized that saving money just from working at a job is never going to get me ahead in
                  life! Never!
                </p>
                <p>
                  <i>
                    (Yes, I do know that there are some exceptions if you are making a handsome, six figure
                    salary. Then a few years of savings could help you raise enough cash to make some
                    profit-making investments. However, for the majority of people that just isn’t the case.)
                  </i>
                </p>
                <p>So did I give up since my dreams basically got crushed?</p>
                <p>Nope, nope!</p>
                <p>
                  From all the books I read and research I did… I realized that in order to get ahead, you
                  need to do something that the majority of people aren’t doing or do something on your own.
                  What does that mean exactly? Well… either you make your money work for you (ex: investing,
                  real estate, etc..) or put yourself in a position where you can control how much money you
                  can earn (ex: starting a business, side hustle, freelancing, etc..). This could be through
                  one source with virtually unlimited upside, or by creating multiple income streams.
                </p>
                <p>And that’s exactly what I plan on beginning through this site.</p>
                <p>
                  You might be wondering... how did I get to this exact point in my life? Why is this
                  important for me?
                </p>
                <p>
                  Well let me share something about my history and tell you where I see myself going from
                  here.
                </p>
                <h3>A BRIEF STORY....</h3>
                <br />
                <p>
                  I grew up in Canada and never really knew what I wanted to do for a living. I was good at
                  math and science, so I eventually decided to go into civil engineering. After a couple years
                  working, I wasn’t happy with this career. And around this time I had also started traveling
                  more and quickly caught the traveling bug. After exploring a bit of the world, and going
                  back to work, I realized that I wanted to have the flexibility of being location
                  independent. I also decided that civil engineering wasn’t for me and started the process of
                  finding something better.
                </p>
                <p>
                  This led me down a bunch of business adventures, because I initially had caught the
                  entrepreneurial bug. However, along the way it also got me more into programming and the
                  tech/startup world. Eventually I decided to solely focus on programming and aimed to have a
                  new career as a front end developer. In 2020, I achieved that goal and dream. If you are
                  interested in reading a more detailed version about how I did that, then have a read of{' '}
                  <LinkWrapper
                    display="inline"
                    to={ROUTES.BLOG_POST.toUrl('/b/david-nowaks-story-how-it-all-started/')}
                  >
                    my story towards changing careers
                  </LinkWrapper>
                  .
                </p>
                <p>
                  Currently, I’m still working in this career as I continue to build and develop my skills. So
                  far I’m very happy with my progress and what I've achieved in successfully switching careers
                  into tech. As an added bonus, I’ve also achieved my goal of being location independent.
                </p>
                <p>Totally awesome!!</p>
                <p>
                  However, I’m still not fully where I want to be in life. I feel like I can achieve more and
                  I am fully capable of doing so. I also feel like I’m wasting my talent and abilities working
                  in a low level position. I know I’m fully capable of starting my own SaaS company, joining a
                  startup as a co-founder, or doing something equally impressive.
                </p>
                <p>
                  In the back of my mind, I also know that I cannot get ahead in life by just working for
                  someone else. I want to be able to have another reliable source of income that allows me to
                  fully control how much I can earn. An income source where the more time and energy I invest
                  into it, my income also grows with it.{' '}
                </p>
                <p>
                  I plan on experimenting and trying numerous different things, while documenting and sharing
                  my journey along the way. My hope is that I’ll be passionate enough on one idea, or project,
                  to keep pushing forward on it until it starts working.
                </p>
                <p style={{ 'text-align': 'right' }}>
                  - <i>David Nowak, 2021</i>
                </p>
                <hr />
                <h3>MY AIM FOR YOU</h3>
                <br />
                <p>
                  I want this site to either inspire you to do something great yourself, or for you to follow
                  in my footsteps of switching careers into tech. I can guarantee that the content which I
                  share with you on this site will be done in an open, honest, and high standard way. I’ll
                  also fully commit to producing great and valuable information on both my blog and YouTube
                  channel. May you only have the courage to explore, follow along and be inspired!
                </p>
                <p>
                  To get you started on whatever path you may take, here are some of my most valuable
                  resources that have tremendously helped me along the way.
                </p>
                <b id="top-books">My top book recommendations:</b>
                <ol>
                  <li>The Millionaire Fastlane</li>
                  <li>How to Win Friends and Influence People</li>
                  <li>Awaken the Giant Within (or alternatively… Personal Power II)</li>
                  <li>The Lean Startup</li>
                  <li>The Power of Habit</li>
                </ol>
                <b id="programming-recommendations">
                  My top resources for learning website development / programming:
                </b>
                <br />
                <i>(In order from first used to last)</i>
                <ol>
                  <li>
                    <LinkWrapper display="inline" to="https://freecodecamp.org">
                      freeCodeCamp
                    </LinkWrapper>{' '}
                    <i> - very beginner to beginner (Free)</i>
                  </li>
                  <li>
                    <LinkWrapper display="inline" to="https://codecademy.com">
                      Codecademy
                    </LinkWrapper>{' '}
                    <i> - very beginner to intermediate (Free & paid)</i>
                  </li>
                  <li>
                    <LinkWrapper
                      display="inline"
                      to="https://coursera.org/learn/html-css-javascript-for-web-developers?action=enroll"
                    >
                      Coursera: HTML, CSS & JS for Web Developers{' '}
                    </LinkWrapper>{' '}
                    <i> - beginner (Free to audit)</i>
                  </li>
                  <li>
                    <LinkWrapper display="inline" to="https://watchandcode.com">
                      Watch and Code
                    </LinkWrapper>{' '}
                    <i> - beginner to intermediate (Free & paid)</i>
                  </li>
                  <li>
                    <LinkWrapper
                      display="inline"
                      to="https://academy.zerotomastery.io/p/complete-web-developer-zero-to-mastery/?affcode=441520_pdrjuzvz"
                    >
                      Zero To Mastery: The Complete Web Developer
                    </LinkWrapper>{' '}
                    <i>- very beginner to intermediate (Paid)</i>
                  </li>
                  <li>
                    <LinkWrapper display="inline" to="https://javascript.info/">
                      The Modern JavaScript Tutorial
                    </LinkWrapper>{' '}
                    <i> - beginner to advanced (Free)</i>
                  </li>
                </ol>
                <b>Investing resources:</b>
                <ol>
                  <li>
                    <LinkWrapper display="inline" to="https://www.trading212.com/invite/GvTnL4XV">
                      Trading 212
                    </LinkWrapper>{' '}
                    <i> - Create an account & get a free stock worth up to $100</i>
                  </li>
                </ol>
                <Disclaimer />
              </div>
            </PostFullContent>
          </PostFull>
        </SiteMain>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default About;
